import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import NpeFreeTextExperiment from './NpeFreeTextExperiment';

import NonPayEnableHelpMsg from 'components/Core/NonPayEnabled/UnpayableCard/Growth/NonPayEnableHelpMsg/NonPayEnableHelpMsg';
import { expStatus } from 'components/Core/NonPayEnabled/UnpayableCard/Growth/consts/consts';
import { useNonPayEnableCookie } from 'components/Core/NonPayEnabled/UnpayableCard/Growth/hooks/useNonPayEnableCookie';
import Button from 'components/Shared/Button/Button';
import Hr from 'components/Shared/Hr/Hr';
import Spinner from 'components/Shared/Spinner/Spinner';
import { useIsMobileScreen } from 'hooks/useIsMobile';
import SegmentIO from 'reporting/SegmentIO';
import { sendMerchantNotification } from 'store/insight/slice';
import { modalActions } from 'store/modal/slice';
import { colors } from 'styles/cp';
import { Modal } from 'types/Modal';
const oinpEventName = 'GrowthNonPayEnableEmailEvent';
const oinpSourceObjectType = 'GROWTH_NON_PAY_ENABLE_EMAIL';

export interface Props {
  companyId: string;
  domainId: string;
  companyName: string;
  merchantEmail: string;
  nonPayEnableEmailStatus: string;
  sendMerchantNotification: any;
  showModal: (payload: Pick<Modal, 'component' | 'componentProps'>, state?: Partial<Modal>) => void;
  hideModal: () => void;
  isFreeTextModalEnabled: any;
}

const NonPayEnable: React.FC<Props> = ({
  companyId,
  domainId,
  companyName,
  merchantEmail,
  nonPayEnableEmailStatus,
  sendMerchantNotification,
  showModal,
  hideModal,
  isFreeTextModalEnabled,
}) => {
  const [firstVisitAnalyticsSent, setFirstVisitAnalyticsSent] = useState<boolean>();
  const [nonPayEnableStatus, setNonPayEnableStatus] = useState<expStatus>();
  const [isFirstVisit, setIsFirstVisit] = useState<boolean>();
  const { updateNpeCookie, getNpeCookie, setNpeCookie } = useNonPayEnableCookie({
    domainId,
    nonPayEnableStatus,
    setNonPayEnableStatus,
  });

  const isMobile = useIsMobileScreen(768);

  useEffect(() => {
    const isFirstVisit = getNpeCookie()?.isFirstVisit;
    setIsFirstVisit(isFirstVisit);
  }, []);

  const [OINPEventStatus, setOINPEventStatus] = useState<string | undefined>();

  // @growth_exp - freetext
  useEffect(() => {
    if (OINPEventStatus === 'SENT_SUCCESSFULLY') {
      setNpeCookie({
        ...getNpeCookie(),
        selection: expStatus.FREE_TEXT_EMAIL_SENT,
        isFirstVisit: true,
      });
    } else if (OINPEventStatus === 'ERROR') {
      setNpeCookie({
        ...getNpeCookie(),
        selection: expStatus.RETURNED_ERROR,
        isFirstVisit: true,
      });
    }
  }, [OINPEventStatus]);

  useEffect(() => {
    (() => {
      if (nonPayEnableStatus === expStatus.PENDING_REQUEST) {
        if (nonPayEnableEmailStatus === 'SENT_SUCCESSFULLY') {
          setNonPayEnableStatus(expStatus.CLICKED_YES);
          setNpeCookie({
            ...getNpeCookie(),
            isFirstVisit: false,
            creationTimestamp: new Date().getTime().toString(),
          });
        } else if (nonPayEnableEmailStatus === 'ERROR') {
          setNonPayEnableStatus(expStatus.RETURNED_ERROR);
        }
      }
      updateNpeCookie();
    })();
  }, [nonPayEnableEmailStatus, nonPayEnableStatus, isFirstVisit]);

  const onClickYes = async () => {
    sendAnalytics('clicked', 'yes_respond');
    setNonPayEnableStatus(expStatus.PENDING_REQUEST);
    await sendMerchantNotification({
      eventName: oinpEventName,
      eventsourceObjectType: oinpSourceObjectType,
    });
  };

  const onClickNo = async () => {
    sendAnalytics('clicked', 'no_respond');
    setNonPayEnableStatus(expStatus.CLICKED_NO);
  };

  const renderActions = () => {
    return (
      <div>
        <NonPayEnableHelpMsg
          messageId="NON_PAY_ENABLE_EXP_START_ALT"
          companyName={companyName}
          defaultMessage={`Looking for a way to pay? We can let ${companyName} know you’re not sure how.`}
          merchantEmail={merchantEmail}
          withHr={true}
        />
        <div className={`non-pay-enable-button${isMobile ? '-mobile' : ''}`}>
          <Button data-cy="growth_exp_click_yes" onClick={onClickYes} buttonType={'primary'}>
            <span>
              <FormattedMessage
                id="NON_PAY_ENABLE_EXP_YES_BUTTON_ALT"
                defaultMessage="Yes, let them know"
              />
            </span>
          </Button>
          <Button
            data-cy="growth_exp_click_no"
            onClick={onClickNo}
            buttonType={'default'}
            backgroundColor="empty"
          >
            <span>
              <FormattedMessage id="NON_PAY_ENABLE_EXP_NO_BUTTON_ALT" defaultMessage="No thanks" />
            </span>
          </Button>
          {/* todo - @growth_exp - freetext */}
        </div>
        {isFreeTextModalEnabled && (
          <NpeFreeTextExperiment
            companyName={companyName}
            merchantEmail={merchantEmail}
            showModal={showModal}
            hideModal={hideModal}
            setOINPEventStatus={setOINPEventStatus}
          />
        )}
        <Hr />
        {/* language=SCSS */}
        <style jsx>
          {`
            .non-pay-enable-button {
              align-items: center;
              display: flex;
              padding-top: 15px;
              justify-content: center;
              column-gap: 20px;
              margin-bottom: 20px;
            }
            .non-pay-enable-button-mobile {
              column-gap: 20px;
              align-items: center;
              display: flex;
              padding-top: 15px;
              justify-content: center;
              column-gap: 10px;
              margin-bottom: 20px;
              span {
                padding: 0;
                margin: 0;
                font-size: 14px;
              }
            }
          `}
        </style>
      </div>
    );
  };

  const renderClickedYes = () => {
    return (
      <div>
        <NonPayEnableHelpMsg
          messageId="NON_PAY_ENABLE_EXP_CLICKED_YES_1_ALT"
          companyName={companyName}
          defaultMessage={`We let ${companyName} know you're not sure how to pay. They should reach out to you soon.`}
          merchantEmail={merchantEmail}
          withHr={true}
        />
        <NonPayEnableHelpMsg
          messageId="NON_PAY_ENABLE_EXP_CLICKED_YES_2_ALT"
          companyName={companyName}
          defaultMessage={`You can also contact them directly at ${merchantEmail}.`}
          merchantEmail={merchantEmail}
          withHr={false}
        />
      </div>
    );
  };

  const renderClickedNo = () => {
    return (
      <div>
        <NonPayEnableHelpMsg
          messageId="NON_PAY_ENABLE_EXP_CLICKED_NO_ALT"
          companyName={companyName}
          defaultMessage={`Okay, you can contact them directly at ${merchantEmail}.`}
          merchantEmail={merchantEmail}
          withHr={true}
        />
      </div>
    );
  };

  const renderPendingRequest = () => {
    return (
      <div className="non-pay-enable-spinner">
        <Hr borderColor={colors.gray04} />
        <Spinner width={100} />
        {/* language=SCSS */}
        <style jsx>
          {`
            .non-pay-enable-spinner {
              flex-direction: column;
              align-items: center;
              display: flex;
              padding-top: 15px;
              justify-content: center;
              column-gap: 20px;
            }
          `}
        </style>
      </div>
    );
  };

  const renderErrorMessage = () => {
    return (
      <div>
        <NonPayEnableHelpMsg
          messageId="NON_PAY_ENABLE_EXP_ERROR_1_ALT"
          companyName={companyName}
          defaultMessage={`We’re having some trouble on our end.`}
          merchantEmail={merchantEmail}
          withHr={true}
        />
        <br />
        <NonPayEnableHelpMsg
          messageId="NON_PAY_ENABLE_EXP_CONTACT_MESSAGE_ALT"
          companyName={companyName}
          defaultMessage={`You can contact ${companyName} directly at ${merchantEmail}`}
          merchantEmail={merchantEmail}
          withHr={false}
        />
      </div>
    );
  };

  const renderFirstTimeVisitContent = () => {
    if (nonPayEnableStatus === expStatus.PENDING_SELECTION) {
      if (!firstVisitAnalyticsSent) {
        sendAnalytics('loaded', 'respond_how_to_pay', 'external_link');
        setFirstVisitAnalyticsSent(true);
      }
      return renderActions();
    } else if (nonPayEnableStatus === expStatus.PENDING_REQUEST) {
      return renderPendingRequest();
    } else if (nonPayEnableStatus === expStatus.CLICKED_YES) {
      sendAnalytics('loaded', 'yes_respond_how_to_pay');
      return renderClickedYes();
    } else if (nonPayEnableStatus === expStatus.CLICKED_NO) {
      return renderClickedNo();
    } else if (nonPayEnableStatus === expStatus.RETURNED_ERROR) {
      sendAnalytics('loaded', 'error_respond_how_to_pay');
      return renderErrorMessage();
    } else if (nonPayEnableStatus === expStatus.FREE_TEXT_EMAIL_SENT) {
      sendAnalytics('loaded', 'error_respond_how_to_pay');
      return renderNpeFreeTextHelperMessage('SENT_SUCCESSFULLY');
    }
    return null;
  };

  const renderAlreadyClickedContent = () => {
    return (
      <>
        {getNpeCookie()?.selection === expStatus.CLICKED_NO && renderSecondVisitClickedNoMessage()}
        {getNpeCookie()?.selection === expStatus.CLICKED_YES &&
          renderSecondVisitClickedYesMessage()}
        {getNpeCookie()?.selection === expStatus.FREE_TEXT_EMAIL_SENT &&
          renderSecondVisitClickedYesMessage()}
      </>
    );
  };

  const renderSecondVisitClickedNoMessage = () => {
    return (
      <div>
        <NonPayEnableHelpMsg
          messageId="NON_PAY_ENABLE_EXP_REFRESHED_NO_ALT"
          companyName={companyName}
          defaultMessage={`Contact ${companyName} if you’re not sure how to pay this invoice.`}
          merchantEmail={
            <>
              <br />
              {merchantEmail}.
            </>
          }
          withHr={true}
        />
      </div>
    );
  };

  const renderSecondVisitClickedYesMessage = () => {
    return (
      <div>
        <NonPayEnableHelpMsg
          messageId="NON_PAY_ENABLE_EXP_REFRESHED_YES_ALT"
          companyName={companyName}
          defaultMessage={`Still need help?`}
          merchantEmail={
            <>
              <br />
              {merchantEmail}.
            </>
          }
          withHr={true}
        />
        <NonPayEnableHelpMsg
          messageId="NON_PAY_ENABLE_EXP_CONTACT_MESSAGE_ALT"
          companyName={companyName}
          defaultMessage={`You can contact ${companyName} directly at ${merchantEmail}`}
          merchantEmail={
            <>
              <br />
              {merchantEmail}.
            </>
          }
          withHr={false}
        />
      </div>
    );
  };

  const renderNpeFreeTextHelperMessage = (freeTextEmailOINPEventStatus: string | undefined) => {
    return (
      <>
        {freeTextEmailOINPEventStatus === 'ERROR' ? (
          renderErrorMessage()
        ) : (
          <NonPayEnableHelpMsg
            messageId="NON_PAY_ENABLE_EXP_CONTACT_MESSAGE_ALTaa"
            companyName={companyName}
            defaultMessage={`Done! You’re message was sent to ${merchantEmail}. 
You should hear from them soon.`}
            merchantEmail={
              <>
                <br />
                {merchantEmail}.
              </>
            }
            withHr={true}
          />
        )}
      </>
    );
  };

  const renderNonPayEnable = () => {
    if (typeof isFirstVisit === 'boolean' && !isFirstVisit) {
      sendAnalytics('loaded', 'return_after_respond_how_to_pay', 'external_link');
      return renderAlreadyClickedContent();
    } else {
      return !OINPEventStatus
        ? renderFirstTimeVisitContent()
        : renderNpeFreeTextHelperMessage(OINPEventStatus);
    }
  };

  const sendAnalytics = (
    uiAction: 'clicked' | 'loaded',
    uiObjectDetail?: string,
    uiAccessPoint?: string
  ) => {
    const analyticsPayload = {
      clicked: {
        action: 'engaged',
        object: 'transaction',
        ui_action: 'clicked',
        ui_object: 'button',
        ui_object_detail: uiObjectDetail,
        ui_access_point: 'transaction_flow',
        company_id: companyId,
        domain_id: domainId.split(':').pop(),
      },
      viewed: {
        action: 'viewed',
        object: 'transaction',
        ui_action: 'loaded',
        ui_object: 'page',
        ui_object_detail: uiObjectDetail,
        ui_access_point: uiAccessPoint || 'transaction_flow',
        company_id: companyId,
        domain_id: domainId.split(':').pop(),
      },
    };

    SegmentIO.sendEvent(
      uiAction === 'clicked' ? 'transaction:engaged' : 'transaction:viewed',
      uiAction === 'clicked' ? analyticsPayload.clicked : analyticsPayload.viewed
    );
  };

  return renderNonPayEnable();
};

// @ts-ignore
const mapStateToProps = ({ insight: { nonPayEnableEmailStatus } }) => {
  return {
    nonPayEnableEmailStatus,
  };
};

export default connect(mapStateToProps, {
  sendMerchantNotification,
  showModal: modalActions.show,
  hideModal: modalActions.hide,
})(NonPayEnable);
