import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import Hr from 'components/Shared/Hr/Hr';
import { breakpoints, colors, fontSize } from 'styles/cp';

export interface Props {
  companyName: string;
  isPayEnabled: boolean;
  paymentDetailsMessage: string;
  allowOnlinePPAAMPayment?: boolean;
}

const ConfirmationEmailMsg: React.FC<Props> = ({
  companyName,
  isPayEnabled,
  paymentDetailsMessage,
  allowOnlinePPAAMPayment,
}) => {
  const getPayNowMessage = () => {
    if ((isPayEnabled && !allowOnlinePPAAMPayment) || !paymentDetailsMessage) {
      return (
        <FormattedMessage
          id="PAYNOW_PAYMENT_NOT_ENABLED_ALT"
          values={{ '0': companyName }}
          defaultMessage={`Contact ${companyName} if you’re not sure how to pay this invoice.`}
        />
      );
    }
    return (
      <div>
        {!allowOnlinePPAAMPayment && (
          <p className="payment-instructions-header">
            <FormattedMessage
              id="MERCHANT_PAYMENT_INSTRUCTIONS"
              defaultMessage="Payment instructions"
            />
          </p>
        )}
        <span className="payment-instructions-msg">{paymentDetailsMessage}</span>
      </div>
    );
  };

  return (
    <Fragment>
      <div className="contact-message" aria-label="Standard Payment help message">
        {<Hr borderColor={colors.gray04} />}
        {getPayNowMessage()}
      </div>

      <style jsx>{`
        .contact-message {
          font-size: ${fontSize.md};
          color: ${colors.darkGray};
          display: block;
          text-align: center;
          line-height: 1.3;
          margin: '0 40px 0 15px';

          @media screen and (max-width: ${breakpoints.mdl}) {
            font-size: ${fontSize.xs};
            padding: 0;
            margin: 10px 0;
            text-align: left;
          }

          .payment-instructions-header {
            font-size: ${fontSize.xs};
            color: ${colors.gray};
            font-family: AvenirNextforINTUIT-Demi;
            font-weight: normal;
            display: block;
            margin-bottom: 4px;
            text-transform: uppercase;
            line-height: 1.43;
          }

          .payment-instructions-msg {
            font-size: ${fontSize.xs};
            font-family: AvenirNextforINTUIT-Regular;
            color: ${colors.gray};
            display: block;
            line-height: 20px;
          }
        }
      `}</style>
    </Fragment>
  );
};

export { ConfirmationEmailMsg };

export default ConfirmationEmailMsg;
