import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import SelectQboStyle from './SelectQboStyle';
import useFreeTextModalNls from './hooks/useFreeTextModalNls';

import { ValidatedInputQboStyle } from 'components/Core/NonPayEnabled/UnpayableCard/Growth/NpeFreeTextExperiment/ValidatedInputQboStyle';
import { useNpeFreeTextAnalytics } from 'components/Core/NonPayEnabled/UnpayableCard/Growth/NpeFreeTextExperiment/hooks/useNpeFreeTextAnalytics';
import {
  EmailModalGetters,
  EmailModalSetters,
} from 'components/Core/NonPayEnabled/UnpayableCard/Growth/consts';
import ActionModal from 'components/Shared/ActionModal';
import { useIsMobileScreen } from 'hooks/useIsMobile';
import {
  isFormValid,
  isFreeTextValid,
  isNameValid,
  isSenderContactInfoValid,
} from 'shared/freeTextModalValidators';
import { ContactInfoType } from 'shared/types';
import { colors, breakpoints } from 'styles/cp';

export interface Props {
  hide: () => void;
  onContinue: () => void;
  companyName: string;
  emailModalSetters: EmailModalSetters;
  emailModalGetters: EmailModalGetters;
  intl: IntlShape;
}

const NpeFreeTextModal: React.FC<Props> = ({
  hide,
  onContinue,
  companyName,
  emailModalSetters,
  emailModalGetters,
  intl,
}) => {
  const {
    setFreeTextSenderName,
    setFreeTextSenderContactInfo,
    setFreeTextSenderContactType,
    setFreeTextMessage,
  } = emailModalSetters;
  const {
    freeTextSenderName,
    freeTextSenderContactInfo,
    freeTextSenderContactType,
    freeTextMessage,
  } = emailModalGetters || '';

  // save modal form state to allow editing after closing the modal
  const [senderName, setSenderName] = useState<string>();
  const [senderContactInfo, setSenderContactInfo] = useState<string>();
  const [senderContactInfoType, setSenderContactInfoType] = useState<ContactInfoType>('Email');
  const [message, setMessage] = useState<string>();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [isDropdownMouseOver, setIsDropdownMouseOver] = useState<boolean>(false);
  const isMobile = useIsMobileScreen(768);
  const { sendModalOpenedEvent, sendFieldEditEvent } = useNpeFreeTextAnalytics();
  const {
    getContactInfoTypePlaceholder,
    getNameInputPlaceholder,
    getFreeTextInputPlaceholder,
    getContactInfoInputError,
    getNameInputError,
    getFreeTextInputError,
    getFreeTextInputLabel,
    getNameInputLabel,
    getContactInfoInputLabel,
    getHeaderNls,
    getContinueButtonNls,
    getCancelButtonNls,
  } = useFreeTextModalNls(intl, senderContactInfoType);

  useEffect(() => {
    sendModalOpenedEvent();
  }, []);

  useEffect(() => {
    isFormValid(senderName || '', senderContactInfo || '', senderContactInfoType, message || '');
  }, [isFormValid]);

  useEffect(() => {
    setSenderName(freeTextSenderName);
    setSenderContactInfo(freeTextSenderContactInfo);
    setSenderContactInfoType(freeTextSenderContactType);
    setMessage(freeTextMessage);
  }, [freeTextSenderName, freeTextSenderContactInfo, freeTextSenderContactType, freeTextMessage]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFreeTextSenderName(e.target.value as string);
    setSenderName(e.target.value as string);
  };
  const handleContactTypeChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLOptionElement>) => {
    setIsDropdownOpen(!isDropdownOpen);
    setFreeTextSenderContactType(e.target.textContent as ContactInfoType);
    setSenderContactInfoType(e.target.textContent as ContactInfoType);
    sendFieldEditEvent('contact_info_type', e.target.textContent as ContactInfoType);
  };
  const handleContactInfoChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFreeTextSenderContactInfo(e.target.value as string);
    setSenderContactInfo(e.target.value as string);
  };
  const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFreeTextMessage(e.target.value as string);
    setMessage(e.target.value as string);
  };
  const handleDropdownBlurEvent = async () => {
    isDropdownMouseOver
      ? await setTimeout(() => {
          setIsDropdownOpen(false);
        }, 300)
      : setIsDropdownOpen(false);
    // handles race condition on click when dropdown is open
  };

  return (
    <ActionModal
      hide={hide}
      onContinue={onContinue}
      continueDisabled={
        !isFormValid(
          senderName || '',
          senderContactInfo || '',
          senderContactInfoType,
          message || ''
        )
      }
      headerIntl={getHeaderNls(companyName)}
      continueIntl={getContinueButtonNls()}
      cancelIntl={getCancelButtonNls()}
      hideOnContinueFinish={false}
      bodyContainerWidth={'100%'}
      removeDefaultBackground={true}
      withHr={false}
      customDesignKey={'freeTextModal'}
    >
      <form className={'modal-form-container'}>
        <div className={'modal-form-left-col'}>
          <div className={'modal-form-row'}>
            <span aria-label={'From'} className={'modal-form-input-label'}>
              {getNameInputLabel()}
            </span>
          </div>
          <div className={'modal-form-row'}>
            <span aria-label={'Contact Info'} className={'modal-form-input-label'}>
              {getContactInfoInputLabel()}
            </span>
          </div>
          <div className={'modal-form-row'}>
            <span
              className={`modal-form-input-label`}
              aria-label={'Message'}
              style={{ marginTop: (isMobile && '24px') || '0px' }}
            >
              {getFreeTextInputLabel()}
            </span>
          </div>
        </div>

        <div className={'modal-form-right-col'}>
          <div className={'modal-form-row'}>
            <div className={`modal-form-item-wrapper name-input-wrapper`}>
              <ValidatedInputQboStyle
                type={'from'}
                validationFunction={(senderName) => isNameValid(senderName || '')}
                placeholder={getNameInputPlaceholder()}
                errorMessage={getNameInputError()}
                inputCustomStyling={'width: 100%;'}
                required={true}
                value={senderName}
                enableTracking
                onChangeHandler={handleNameChange}
                ariaLabel={'input-from'}
              />
            </div>
          </div>
          <div className={'modal-form-row modal-contact-info-row'}>
            <div className={'contact-info-select-wrapper'}>
              <SelectQboStyle
                style={{ width: isMobile ? '100%' : '190px', fontSize: '16px' }}
                defaultValue={senderContactInfoType}
                value={senderContactInfoType}
                aria-label={'select-contact-info-type'}
                theme={'qbo'}
                aria-expanded={isDropdownOpen}
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                onBlur={handleDropdownBlurEvent}
              />
              <ul
                className={'contact-info-type-dropdown'}
                aria-hidden={!isDropdownOpen}
                style={{ width: isMobile ? '250px' : '190px' }}
                onMouseOver={() => {
                  setIsDropdownMouseOver(true);
                }}
                onMouseLeave={() => {
                  setIsDropdownMouseOver(false);
                }}
                aria-label="contact-options"
              >
                {(['Email', 'Phone number'] as ContactInfoType[]).map(
                  (x: ContactInfoType, i: number) => (
                    <li
                      className={'contact-info-type-option'}
                      aria-label={`Choose ${x}`}
                      key={i}
                      value={x}
                      onClick={(e: any) => {
                        handleContactTypeChange(e);
                      }}
                    >
                      {x}
                    </li>
                  )
                )}
              </ul>
            </div>
            <div className={'modal-form-item-wrapper contact-info-input-wrapper'}>
              <ValidatedInputQboStyle
                validationFunction={(senderContactInfo) =>
                  isSenderContactInfoValid(senderContactInfo || '', senderContactInfoType)
                }
                errorMessage={getContactInfoInputError()}
                inputCustomStyling={'width: 100%; height: 16px;'}
                onChangeHandler={handleContactInfoChange}
                placeholder={getContactInfoTypePlaceholder()}
                value={senderContactInfo}
                type={`contact_info`}
                ariaLabel={'input-contact-info'}
                enableTracking
                required
              />
            </div>
          </div>
          <div className={'modal-form-item-wrapper free-text-message-input-wrapper'}>
            <ValidatedInputQboStyle
              type={'message'}
              validationFunction={(message) => isFreeTextValid(message || '')}
              errorMessage={getFreeTextInputError()}
              onChangeHandler={handleMessageChange}
              placeholder={getFreeTextInputPlaceholder()}
              value={message}
              enableTracking
              isTextArea
              required
            />
            <span className={'modal-disclaimer'}>
              <FormattedMessage
                id="GROWTH_FREE_TEXT_EMAIL_FORM_DISCLAIMER"
                defaultMessage="Do not enter any personal information such as credit card details in this message."
              />
            </span>
          </div>
        </div>
      </form>

      {/* language=scss */}
      <style jsx>
        {`
          .contact-info-type-dropdown {
            display: ${isDropdownOpen ? 'block' : 'none'};
            position: absolute;
            border-radius: 4px;
            width: 100%;
            box-shadow: 0px 1px 4px 1px ${colors.gray05};
            background-color: white;
            padding: 4px 0px;
            font-size: 16px;
            list-style: none;
            margin-top: 4px;
          }
          .contact-info-type-option {
            color: ${colors.gray01};
            cursor: pointer;
            padding: 5px 8px;
            z-index: 999;
            :hover {
              background-color: #2ca01c;
              color: white;
            }
          }

          .modal-form-container {
            display: flex;
            font-family: AvenirNextforINTUIT-Regular;
            margin-top: 15px;
            @media screen and (max-width: ${breakpoints.sm}) {
              margin-top: 0px;
              max-height: 350px;
              overflow: scroll;
            }
            .modal-form-left-col {
              display: flex;
              flex-direction: column;
              width: 20%;
              font-size: 14px;
            }

            .modal-form-right-col {
              display: flex;
              flex-direction: column;
              width: 80%;
            }

            .modal-form-row {
              display: flex;
              flex-wrap: wrap;
              max-height: 195px;
              min-height: 65px;
              margin-bottom: ${isMobile ? '25px' : '5px'};
              align-items: start;
              justify-content: space-between;
            }
            .modal-contact-info-row {
              @media screen and (max-width: ${breakpoints.sm}) {
                margin-bottom: 5px;
              }
            }

            .modal-form-item-wrapper {
              display: flex;
              flex-direction: column;
              margin-top: 6px;
              height: 58px;
              justify-content: start;
            }

            .name-input-wrapper {
              width: 100%;
            }

            .contact-info-select-wrapper {
              margin-top: 2px;
              @media screen and (max-width: ${breakpoints.sm}) {
                width: 100%;
              }
            }

            .contact-info-input-wrapper {
              width: 58%;
              @media screen and (max-width: ${breakpoints.sm}) {
                width: 100%;
              }
            }

            .free-text-message-input-wrapper {
              min-height: ${isMobile ? '100px' : '210px'};
              resize: none;
              justify-content: start;
              @media screen and (max-width: ${breakpoints.sm}) {
                margin-bottom: 25px;
              }
            }

            .modal-form-input-label {
              display: flex;
              color: ${colors.gray02};
              align-items: center;
              padding-top: 12px;
            }

            .modal-disclaimer {
              font-family: AvenirNextforINTUIT-Regular;
              font-size: 14px;
              margin-top: 10px;
              margin-bottom: -30px;
              @media screen and (max-width: ${breakpoints.sm}) {
                padding-bottom: 15px;
                margin-top: 20px;
              }
            }
          }
        `}
      </style>
    </ActionModal>
  );
};

export default injectIntl(NpeFreeTextModal);
