import React, { Fragment, useState } from 'react';

import OpenInvoiceList from 'components/Core/OpenInvoices/List/OpenInvoiceList';
import Card from 'components/Shared/Card/Card';
import Chevron from 'components/Shared/Icons/Chevron';

export interface OpenInvoiceCardProps {
  link: string;
  balance: number;
  docNumber: string;
  dueDate: string;
}

export interface Props {
  invoices: Array<OpenInvoiceCardProps>;
}

const OpenInvoicesNotice: React.FC<Props> = ({ invoices }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Fragment>
      <div className="container">
        <Card>
          <div
            className="card"
            onClick={() => setIsOpen(!isOpen)}
            data-cy="OpenInvoicesNotice-header"
          >
            <div className="description">
              You have
              <span className="invoice-number">{Array.isArray(invoices) && invoices.length}</span>
              unpaid invoices.
            </div>
            <span className="chevron">
              <Chevron height={14} color="black" rotate={isOpen ? '0' : '-90'} />
            </span>
          </div>
          {isOpen && <OpenInvoiceList invoices={invoices} isSuccessScreen={false} />}
        </Card>
      </div>
      {/*language=SCSS*/}
      <style jsx>{`
        .container {
          @keyframes opacityChange {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }

          animation-name: opacityChange;
          animation-duration: 2s;

          .card {
            cursor: pointer;
            padding: 21px 14px;
            font-size: 14px;
            font-family: AvenirNextforINTUIT-Regular;
            position: relative;

            .description {
              padding-right: 80px;
            }

            .invoice-number {
              font-weight: bold;
              padding: 0 4px;
            }

            .chevron {
              position: absolute;
              right: 8px;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      `}</style>
    </Fragment>
  );
};

export default OpenInvoicesNotice;
