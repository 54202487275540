import SegmentIO from 'reporting/SegmentIO';

export type FreeTextAnalyticsFieldType = 'from' | 'contact_info' | 'contact_info_type' | 'message';

export const useNpeFreeTextAnalytics = () => {
  const sendLinkViewedEvent = () => {
    // User saw the link to free text modal
    // @ts-ignore
    SegmentIO.transactionViewed({
      object: 'transaction',
      action: 'viewed',
      ui_object: 'link',
      ui_object_detail: 'send_message_to_merchant_link',
      ui_action: 'loaded',
      ui_access_point: 'transaction_flow',
    });
  };
  const sendUserClickedOnLinkEvent = () => {
    // User click on the ask your accountant link
    SegmentIO.transactionEngaged({
      object: 'transaction',
      action: 'engaged',
      ui_object: 'link',
      ui_object_detail: 'send_message_to_merchant_link',
      ui_action: 'clicked',
      ui_access_point: 'transaction_flow',
    });
  };
  const sendModalOpenedEvent = () => {
    // Email modal is opened
    // @ts-ignore
    SegmentIO.widgetViewed({
      object: 'transaction',
      action: 'viewed',
      ui_object: 'modal',
      ui_object_detail: 'send_message_to_merchant_form',
      ui_action: 'loaded',
      ui_access_point: 'transaction_flow',
    });
  };
  const sendFieldEditEvent = (field: FreeTextAnalyticsFieldType, value?: string | undefined) => {
    // User edited form field
    // @ts-ignore
    SegmentIO.widgetEngaged({
      object: 'transaction',
      object_detail: 'single_invoice_transaction',
      action: 'engaged',
      ui_object: 'modal',
      ui_object_detail: 'send_message_to_merchant_form',
      ui_action: 'typed',
      ui_access_point: 'transaction_flow',
      b2b_data: {
        updated_field: value ? `{${field},${value.toLowerCase()}}` : field.toLowerCase(),
      },
    });
  };
  const sendClickedSendEmailEvent = () => {
    // User clicked "send" in the email modal
    // @ts-ignore
    SegmentIO.widgetEngaged({
      object: 'transaction',
      action: 'engaged',
      ui_object: 'button',
      ui_object_detail: 'send_message_to_merchant_submit',
      ui_action: 'clicked',
      ui_access_point: 'transaction_flow',
    });
  };
  const sendModalClosedEvent = () => {
    // User close the modal by X / Cancel
    // @ts-ignore
    SegmentIO.widgetEngaged({
      object: 'transaction',
      action: 'engaged',
      ui_object: 'closed',
      ui_object_detail: 'send_message_to_merchant_cancel',
      ui_action: 'clicked',
      ui_access_point: 'transaction_flow',
    });
  };
  const sendEmailSuccessEvent = () => {
    // Success sending an email
    // @ts-ignore
    SegmentIO.widgetViewed({
      object: 'transaction',
      action: 'viewed',
      ui_object: 'modal',
      ui_object_detail: 'send_message_to_merchant_success',
      ui_action: 'loaded',
      ui_access_point: 'transaction_flow',
    });
  };
  const sendEmailErrorEvent = (errorMessage: any) => {
    // Error sending an email
    // @ts-ignore
    SegmentIO.widgetViewed({
      object: 'transaction',
      action: 'viewed',
      ui_object: 'modal',
      ui_object_detail: 'send_message_to_merchant_failed',
      ui_action: 'loaded',
      ui_access_point: 'transaction_flow',
      error_message: errorMessage,
    });
  };
  return {
    sendLinkViewedEvent,
    sendUserClickedOnLinkEvent,
    sendModalOpenedEvent,
    sendFieldEditEvent,
    sendModalClosedEvent,
    sendClickedSendEmailEvent,
    sendEmailSuccessEvent,
    sendEmailErrorEvent,
  };
};
