import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import SegmentIO from 'reporting/SegmentIO';
import { getLegalLinks } from 'shared/clientUtils';
import { breakpoints, colors, fontSize } from 'styles/cp';

export interface Props {
  withTOS?: boolean;
  separator?: string;
}

const MainFooter = (props: Props) => {
  const intl = useIntl();
  const { withTOS = true, separator = '\u00A0' } = props;

  const { privacyUrl, tosUrl } = getLegalLinks(intl.locale);

  const eventProps = {
    activity_type: 'optional_actions',
    ui_object: 'link',
    ui_action: 'clicked',
    ui_access_point: 'transaction_flow',
  };

  const onTOSClick = () => {
    SegmentIO.clickTermsOfService();
    SegmentIO.transactionEngaged({
      ...eventProps,
      ui_object_detail: 'terms_of_service',
    });
  };

  const onPrivacyClick = () => {
    SegmentIO.clickPrivacy();
    SegmentIO.transactionEngaged({
      ...eventProps,
      ui_object_detail: 'privacy_statement',
    });
  };

  return (
    <Fragment>
      <style jsx>{`
        .main-footer {
          font-family: AvenirNextforINTUIT-Regular;
          font-size: ${fontSize.xxs};
          text-align: center;
          color: ${colors.ghostGray};
          padding: 0 7px 20px;

          @media (max-width: ${breakpoints.md}) {
          }

          a {
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      `}</style>

      <div className="main-footer">
        <FormattedMessage
          id="INVOICE_ALL_RIGHT_RESERVED"
          values={{
            '0': new Date().getFullYear(),
          }}
          defaultMessage={'© 2018 Intuit Inc. All right reserved'}
        />
        {withTOS && (
          <Fragment>
            {separator}
            <a onClick={onPrivacyClick} href={privacyUrl} target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="INVOICE_VIEW_PAGE_FOOTER_PRIVACY" defaultMessage="Privacy" />
            </a>
            <span> | </span>
            <a onClick={onTOSClick} href={tosUrl} target="_blank" rel="noopener noreferrer">
              <FormattedMessage
                id="INVOICE_VIEW_PAGE_FOOTER_TOS"
                defaultMessage="Terms of service"
              />
            </a>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default MainFooter;
