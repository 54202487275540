import { IntlShape } from 'react-intl';

import { ContactInfoType } from 'shared/types';

export interface Props {
  intl: IntlShape;
  senderContactInfoType: ContactInfoType;
}

const useFreeTextModalNls: (
  intl: IntlShape,
  senderContactInfoType: ContactInfoType
) => {
  getNameInputError: () => string;
  getContactInfoInputError: () => string;
  getContactInfoTypePlaceholder: () => string;
  getNameInputPlaceholder: () => string;
  getFreeTextInputPlaceholder: () => string;
  getFreeTextInputError: () => string;
  getFreeTextInputLabel: () => string;
  getNameInputLabel: () => string;
  getContactInfoInputLabel: () => string;
  getHeaderNls: (companyName: string) => {};
  getContinueButtonNls: () => {};
  getCancelButtonNls: () => {};
} = (intl, senderContactInfoType) => {
  const getHeaderNls = (companyName: string): {} => {
    return {
      id: '*',
      defaultMessage: `Send ${companyName} a message`,
    };
  };
  const getContinueButtonNls = (): {} => {
    return {
      id: 'GROWTH_FREE_TEXT_EMAIL_FORM_SEND',
      defaultMessage: 'Send',
    };
  };
  const getCancelButtonNls = (): {} => {
    return {
      id: 'GROWTH_FREE_TEXT_EMAIL_FORM_CANCEL',
      defaultMessage: 'Cancel',
    };
  };

  const getContactInfoInputError = (): string => {
    return senderContactInfoType === 'Email'
      ? intl.formatMessage({
          id: 'GROWTH_FREE_TEXT_EMAIL_FORM_ERRORS.EMAIL',
          defaultMessage: 'Please enter valid email address',
        })
      : intl.formatMessage({
          id: 'GROWTH_FREE_TEXT_EMAIL_FORM_ERRORS.PHONE',
          defaultMessage: 'Please enter valid phone number',
        });
  };

  const getNameInputError = (): string => {
    return intl.formatMessage({
      id: 'GROWTH_FREE_TEXT_EMAIL_FORM_ERRORS.NAME',
      defaultMessage: 'Please enter valid first and last name less than 50 characters',
    });
  };

  const getFreeTextInputError = (): string => {
    return intl.formatMessage({
      id: 'GROWTH_FREE_TEXT_EMAIL_FORM_ERRORS.MESSAGE',
      defaultMessage: 'Input should contain up to 500 valid characters, without personal info.',
    });
  };

  const getNameInputPlaceholder = (): string => {
    return intl.formatMessage({
      id: 'GROWTH_FREE_TEXT_EMAIL_FORM_PLACEHOLDERS.NAME',
      defaultMessage: `Add your name`,
    });
  };

  const getContactInfoTypePlaceholder = (): string => {
    return senderContactInfoType === 'Email'
      ? intl.formatMessage({
          id: 'GROWTH_FREE_TEXT_EMAIL_FORM_PLACEHOLDERS.EMAIL',
          defaultMessage: `Add your Email`,
        })
      : intl.formatMessage({
          id: 'GROWTH_FREE_TEXT_EMAIL_FORM_PLACEHOLDERS.PHONE',
          defaultMessage: `Add your phone number`,
        });
  };

  const getFreeTextInputPlaceholder = (): string => {
    return intl.formatMessage({
      id: 'GROWTH_FREE_TEXT_EMAIL_FORM_PLACEHOLDERS.MESSAGE',
      defaultMessage: `Let them know what you need or how they can help.`,
    });
  };

  const getNameInputLabel = (): string => {
    return intl.formatMessage({
      id: 'GROWTH_FREE_TEXT_EMAIL_FORM_LABELS.FROM',
      defaultMessage: 'From',
    });
  };

  const getContactInfoInputLabel = (): string => {
    return intl.formatMessage({
      id: 'GROWTH_FREE_TEXT_EMAIL_FORM_LABELS.CONTACT_INFO',
      defaultMessage: 'Contact info',
    });
  };

  const getFreeTextInputLabel = (): string => {
    return intl.formatMessage({
      id: 'GROWTH_FREE_TEXT_EMAIL_FORM_LABELS.MESSAGE',
      defaultMessage: 'Message',
    });
  };

  return {
    getContactInfoTypePlaceholder,
    getNameInputPlaceholder,
    getFreeTextInputPlaceholder,
    getContactInfoInputError,
    getNameInputError,
    getFreeTextInputError,
    getFreeTextInputLabel,
    getNameInputLabel,
    getContactInfoInputLabel,
    getHeaderNls,
    getContinueButtonNls,
    getCancelButtonNls,
  };
};

export default useFreeTextModalNls;
