import React, { Fragment, Component } from 'react';
import { FormattedMessage } from 'react-intl';

import PaymentInfo from 'components/Core/Payment/PaymentInfo/PaymentInfo';
import PaymentRequestInfo from 'components/PaymentReq/Info/PaymentRequestInfo';
import Button from 'components/Shared/Button/Button';
import { Chevron } from 'components/Shared/Icons/Icons';
import MerchantMoreInfo from 'components/Shared/Merchant/MoreInfo/MerchantMoreInfo';
import Separator from 'components/Shared/Separator/Separator';
import SegmentIO from 'reporting/SegmentIO';
import { breakpoints, fontSize, colors } from 'styles/cp';

type ContactInfoModalProps = {
  companyAddress?: Record<string, string>;
  companyEmail?: string;
  companyPhone?: string;
  companyWebAddr?: string;
  number: string;
  hide?: () => void;
};

export const ContactInfoModal = (props: ContactInfoModalProps) => {
  return (
    <div className="w">
      <style jsx>{`
        .w {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          flex-direction: column;
        }

        .w-i {
          width: 320px;
          height: auto;
          background-color: ${colors.white};
          padding: 30px 20px;
          text-align: center;
        }

        .t {
          display: block;
          width: 100%;
          text-align: left;
          font-size: ${fontSize.sm};
          font-family: AvenirNextforINTUIT-Bold;
          font-weight: bold;
        }
      `}</style>
      <div className="w-i">
        <span className="t">
          <FormattedMessage id="SETTINGS_CONTACT_INFO" defaultMessage="Contact info" />
        </span>
        <MerchantMoreInfo
          companyAddress={props.companyAddress}
          companyEmail={props.companyEmail}
          companyPhone={props.companyPhone}
          companyWebAddr={props.companyWebAddr}
          number={props.number}
        />
        <Separator height={25} />
        <Button onClick={props.hide} width="125px" data-cy="invoiceMoreDetails-contact-info-close">
          <FormattedMessage id="SETTINGS_CLOSE" defaultMessage="Close" />
        </Button>
      </div>
    </div>
  );
};
export type Props = {
  companyEmail?: string;
  companyPhone?: string;
  companyWebAddr?: string;
  companyAddress?: object;
  number?: string;
  description?: string;
  txnDate?: string;
  amount?: number;
  balanceAmount?: number;
  currency?: string;
  showModal: (...args: any[]) => any;
  fetchPDFStatus?: string;
  pdfUrl?: string;
  totalAmount?: number;
};
type PaymentRequestMoreDetailsState = {
  isOpen: boolean;
};
class PaymentRequestMoreDetails extends Component<Props, PaymentRequestMoreDetailsState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    const methodsToBind = ['onShowContactInfoClick'];
    // @ts-ignore
    methodsToBind.forEach((methodName) => (this[methodName] = this[methodName].bind(this)));
  }
  onShowContactInfoClick() {
    this.props.showModal({
      component: ContactInfoModal,
      componentProps: {
        companyEmail: this.props.companyEmail,
        companyPhone: this.props.companyPhone,
        companyWebAddr: this.props.companyWebAddr,
        companyAddress: this.props.companyAddress,
        number: this.props.number,
        showCloseButton: false,
      },
    });
    SegmentIO.clickContactInformation();
  }
  render() {
    const { description, txnDate, amount, balanceAmount, currency, totalAmount } = this.props;
    return (
      <Fragment>
        <style jsx>{`
          .more-details {
            text-align: center;
            margin-top: 15px;
            display: none;

            @media screen and (max-width: ${breakpoints.md}) {
              display: block;
              margin-top: 0;
              background-color: ${colors.gray08};
            }

            .header {
              cursor: pointer;
              background-color: ${colors.white};
              padding-bottom: 4px;

              .view-request-label {
                color: ${colors.lightBlue};
                font-size: ${fontSize.xs};
                user-select: none;
                font-family: AvenirNextforINTUIT-Medium;
              }

              :global(svg) {
                transition: all 0.15s linear;
                transform: rotate(${this.state.isOpen ? 0 : 180}deg);
              }
            }

            .content-holder {
              max-height: ${this.state.isOpen ? '1000px' : 0};
              overflow: hidden;
              transition: max-height 0.35s ease;
              box-shadow: inset 0px 5px 20px 0px ${colors.black + '09'};

              .content {
                margin: 16px 40px 0;
              }
              .cta-w {
                padding: 0;
                align-items: center;
                justify-content: space-evenly;
                flex-direction: column;
                max-width: 266px;
                margin: 20px auto;
              }

              a {
                text-decoration: inherit;
                color: inherit;
              }
            }
          }
        `}</style>

        <div className="more-details">
          <div
            className="header"
            onClick={() => {
              SegmentIO.clickShowOrHideDetails(!this.state.isOpen);
              this.setState({ isOpen: !this.state.isOpen });
            }}
          >
            <span className="view-request-label">
              <FormattedMessage
                id="VIEW_DESCRIPTION_OF_GOODS_OR_SERVICES"
                defaultMessage="View description of goods or services"
              />
            </span>
            <Separator display="inline-block" width={5} />
            <Chevron width={12} height={16} color={colors.lightBlue} />
          </div>
          <div className="content-holder">
            <div className="content">
              <PaymentRequestInfo
                requestDate={txnDate}
                amount={amount}
                currency={currency}
                description={description}
              />
              <PaymentInfo
                balanceAmount={balanceAmount}
                amount={totalAmount}
                currency={currency}
                beforePay={true}
              />
              <div className="flex cta-w flex-column">
                <Button
                  size="standard"
                  buttonType="tertiary"
                  width="100%"
                  onClick={this.onShowContactInfoClick}
                  data-cy="invoiceMoreDetails-contact-info"
                >
                  <FormattedMessage id="MERCHANT_DETAILS" defaultMessage="Merchant details" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default PaymentRequestMoreDetails;
