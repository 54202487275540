import React, { useEffect } from 'react';

import { expStatus } from 'components/Core/NonPayEnabled/UnpayableCard/Growth/consts';

export interface NonPayEnableState {
  selection?: expStatus | string;
  isFirstVisit?: boolean;
  creationTimestamp?: number | string;
}

export interface UseNonPayEnabledProps {
  domainId: string;
  nonPayEnableStatus: expStatus | undefined;
  setNonPayEnableStatus: React.Dispatch<React.SetStateAction<expStatus | undefined>>;
}

export const useNonPayEnableCookie = (props: UseNonPayEnabledProps) => {
  const { domainId, setNonPayEnableStatus, nonPayEnableStatus } = props;
  const npeLocalStorageKey = `npe-${domainId.split(':').pop()}`;

  const setNpeCookie = (nonPayEnableState: NonPayEnableState): void => {
    const { selection, isFirstVisit, creationTimestamp } = nonPayEnableState;
    window.localStorage.setItem(
      npeLocalStorageKey,
      JSON.stringify({
        selection: selection !== undefined ? selection : expStatus.PENDING_SELECTION,
        isFirstVisit: isFirstVisit !== undefined ? isFirstVisit : true,
        creationTimestamp: creationTimestamp ? creationTimestamp : undefined,
      })
    );
  };

  const getNpeCookie = (): NonPayEnableState | null => {
    if ((window.localStorage.getItem(npeLocalStorageKey) as NonPayEnableState) !== null) {
      return JSON.parse(
        // @ts-ignore
        window.localStorage.getItem(npeLocalStorageKey) as NonPayEnableState
      );
    } else {
      return null;
    }
  };

  const isNpeCookieExpired = (creationTimeString: string) => {
    const now = new Date().getTime();
    const maxDays = 180;
    const timeToLive: Number = 1000 * 60 * 60 * 24 * maxDays; // ttl = 180 days
    const timeAlive = now - Number(creationTimeString as string);

    if (creationTimeString !== undefined) {
      return timeAlive > timeToLive;
    }
    return false;
  };

  const clearNpeExpiredCookies = () => {
    Object.keys(localStorage)
      .filter((keys) => keys.startsWith('npe-'))
      .forEach((key) => {
        const creationTimestamp = JSON.parse(
          window.localStorage.getItem(key) as string
        ).creationTimestamp;
        if (
          isNpeCookieExpired(creationTimestamp as string) ||
          getNpeCookie()?.selection === expStatus.RETURNED_ERROR ||
          getNpeCookie()?.selection === expStatus.PENDING_REQUEST
        ) {
          localStorage.removeItem(key);
        }
      });
  };

  useEffect(() => {
    clearNpeExpiredCookies();
    const selection = getNpeCookie()?.selection;
    const isFirstVisit = getNpeCookie()?.isFirstVisit;
    const isInitialState =
      (selection === expStatus.PENDING_SELECTION && isFirstVisit) || !selection;
    if (getNpeCookie() === null || isInitialState) {
      setNpeCookie({
        selection: expStatus.PENDING_SELECTION,
        isFirstVisit: true,
        creationTimestamp: new Date().getTime().toString(),
      });
      setNonPayEnableStatus(expStatus.PENDING_SELECTION);
    }

    if (selection !== expStatus.PENDING_SELECTION && isFirstVisit) {
      setNonPayEnableStatus(parseInt(selection as string));
      setNpeCookie({
        ...getNpeCookie(),
        selection: selection,
        isFirstVisit: false,
      });
    }
  }, []);

  const updateNpeCookie = (): void => {
    nonPayEnableStatus &&
      setNpeCookie({
        ...getNpeCookie(),
        selection: nonPayEnableStatus,
        isFirstVisit: getNpeCookie()?.isFirstVisit,
        creationTimestamp: new Date().getTime().toString(),
      });
  };

  return {
    updateNpeCookie,
    getNpeCookie,
    setNpeCookie,
    clearNpeExpiredCookies,
  };
};
