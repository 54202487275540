import React, { Fragment } from 'react';
import { FormattedNumber, FormattedMessage } from 'react-intl';

import DataRow from 'components/Shared/DataRow/DataRow';
import FormattedDateNoTimeZone from 'components/Shared/FormattedDateNoTimeZone/FormattedDateNoTimeZone';
import { breakpoints, colors } from 'styles/cp';

export type Props = {
  requestDate?: string;
  amount?: number;
  isFullyPaid?: boolean;
  isSuccessScreen?: boolean;
  description?: string;
  currency?: string;
};

const PaymentRequestInfo: React.FC<Props> = (props) => {
  const { requestDate, amount, isFullyPaid, isSuccessScreen, description, currency } = props;
  return (
    <Fragment>
      <style jsx>{`
        .payment-request-info {
          padding: 0;
          margin: 0;
          @media (max-width: ${breakpoints.md}) {
            max-width: 400px;
            margin: 5px auto;
          }
          @media (max-width: ${breakpoints.sm}) {
            max-width: 576px;
          }

          .description-container {
            text-align: left;
            padding-top: 14px;

            .description-title {
              font-size: 14px;
              display: block;
              font-family: AvenirNextforINTUIT-Medium;
            }

            .request-description {
              font-size: 14px;
              font-family: AvenirNextforINTUIT-Regular;
              white-space: pre-line;
            }
          }
        }
      `}</style>

      <ul className="payment-request-info">
        {requestDate && (
          <DataRow
            idValue="PAYMENT_REQUEST_DATE"
            defaultMessage="Request date"
            value={<FormattedDateNoTimeZone value={requestDate} />}
          />
        )}
        {description && isSuccessScreen && (
          <DataRow
            idValue="SALE_DESCRIPTION"
            defaultMessage="Sale description"
            value={description}
          />
        )}
        {isFullyPaid && !isSuccessScreen && currency && amount && amount > 0 && (
          <DataRow
            idValue="PAYMENT_REQUEST_SUMMARY_REQUESTED_AMOUNT"
            defaultMessage="Requested amount"
            value={<FormattedNumber value={amount} style="currency" currency={currency} />}
          />
        )}
        {!isSuccessScreen && (
          <div className="description-container">
            <span className="description-title">
              <FormattedMessage
                id="PAYMENT_REQUEST_DESCRIPTION"
                defaultMessage="Description of goods or services"
              />
            </span>
            <span className="request-description">{description}</span>
          </div>
        )}
        {!!amount && amount > 0 && isSuccessScreen && (
          <DataRow
            idValue="INVOICE_TOTAL"
            defaultMessage="Total"
            bold={true}
            color={colors.black}
            value={<FormattedNumber value={amount} style="currency" currency={currency} />}
          />
        )}
      </ul>
    </Fragment>
  );
};
export default PaymentRequestInfo;
