import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import Hr from 'components/Shared/Hr/Hr';
import { breakpoints, colors, fontSize } from 'styles/cp';

export interface Props {
  messageId: string;
  defaultMessage: string;
  companyName?: string;
  merchantEmail?: string | React.ReactNode;
  withHr: boolean;
}

const NonPayEnableHelpMsg: React.FC<Props> = ({
  messageId,
  defaultMessage,
  companyName,
  merchantEmail,
  withHr,
}) => {
  return (
    <Fragment>
      <div className="contact-message">
        {withHr ? <Hr borderColor={colors.gray04} /> : <br />}
        <FormattedMessage
          id={messageId}
          // @ts-ignore
          values={{ '0': companyName, '1': merchantEmail }}
          defaultMessage={defaultMessage}
        />
      </div>

      <style jsx>{`
        .contact-message {
          font-size: ${fontSize.md};
          color: ${colors.darkGray};
          display: block;
          text-align: center;
          line-height: 1.3;
          margin: '0 40px 0 15px';

          @media screen and (max-width: ${breakpoints.mdl}) {
            font-size: ${fontSize.xs};
            padding: 0;
            margin: 10px 0;
            text-align: left;
          }

          .payment-instructions-header {
            font-size: ${fontSize.xs};
            color: ${colors.gray};
            font-family: AvenirNextforINTUIT-Demi;
            font-weight: normal;
            display: block;
            margin-bottom: 4px;
            text-transform: uppercase;
            line-height: 1.43;
          }

          .payment-instructions-msg {
            font-size: ${fontSize.xs};
            font-family: AvenirNextforINTUIT-Regular;
            color: ${colors.gray};
            display: block;
            line-height: 20px;
          }
        }
      `}</style>
    </Fragment>
  );
};

export { NonPayEnableHelpMsg };

export default NonPayEnableHelpMsg;
