import React from 'react';

import { ContactInfoType } from 'shared/types';

export enum expStatus {
  PENDING_SELECTION,
  CLICKED_YES,
  CLICKED_NO,
  PENDING_REQUEST,
  RETURNED_ERROR,
  FREE_TEXT_EMAIL_SENT,
}

export type EmailModalSetters = {
  setFreeTextSenderName: React.Dispatch<React.SetStateAction<string | undefined>>;
  setFreeTextSenderContactInfo: React.Dispatch<React.SetStateAction<string | undefined>>;
  setFreeTextSenderContactType: React.Dispatch<React.SetStateAction<ContactInfoType>>;
  setFreeTextMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export type EmailModalGetters = {
  freeTextSenderName: string | undefined;
  freeTextSenderContactInfo: string | undefined;
  freeTextSenderContactType: ContactInfoType;
  freeTextMessage: string | undefined;
};

export type ContactInfo = {
  contactInfoValue: string;
  contactInfoType: ContactInfoType;
};
