import React, { ComponentProps, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import NpeFreeTextModal from './NpeFreeTextModal/NpeFreeTextModal';
import { useNpeFreeTextAnalytics } from './hooks/useNpeFreeTextAnalytics';

import {
  EmailModalGetters,
  EmailModalSetters,
} from 'components/Core/NonPayEnabled/UnpayableCard/Growth/consts';
import { ContactInfoType } from 'shared/types';
import { sendNpeFreeTextEmailEvent } from 'store/insight/slice';
import { colors, breakpoints } from 'styles/cp';
import { Modal } from 'types/Modal';

export interface Props extends ComponentProps<React.ComponentProps<any>> {
  companyName: string;
  showModal: (Component: Pick<Modal, 'component' | 'componentProps'>, args?: Object) => void;
  hideModal: () => void;
  merchantEmail: string;
  sendNpeFreeTextEmailEvent: any;
  freeTextEmailOINPEventStatus: string;
  setOINPEventStatus: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const NpeFreeTextExperiment: React.FC<Props> = ({
  showModal,
  hideModal,
  merchantEmail,
  companyName,
  sendNpeFreeTextEmailEvent,
  freeTextEmailOINPEventStatus,
  setOINPEventStatus,
}) => {
  const {
    sendLinkViewedEvent,
    sendUserClickedOnLinkEvent,
    sendClickedSendEmailEvent,
    sendEmailSuccessEvent,
    sendEmailErrorEvent,
    sendModalClosedEvent,
  } = useNpeFreeTextAnalytics();

  const [freeTextSenderName, setFreeTextSenderName] = useState<string>();
  const [freeTextSenderContactInfo, setFreeTextSenderContactInfo] = useState<string>();
  const [freeTextSenderContactType, setFreeTextSenderContactType] =
    useState<ContactInfoType>('Email');
  const [freeTextMessage, setFreeTextMessage] = useState<string>();
  // Use a ref to store the send function
  const handleClickSend = useCallback(async () => {
    sendClickedSendEmailEvent();
    try {
      await sendNpeFreeTextEmailEvent({
        merchantEmail,
        senderName: freeTextSenderName,
        senderContactInfo: freeTextSenderContactInfo,
        freeTextMessage,
      });
      sendEmailSuccessEvent();
    } catch (e) {
      sendEmailErrorEvent(e);
    }
  }, [freeTextSenderName, freeTextSenderContactInfo, freeTextMessage]);

  const sendRef = useRef(handleClickSend);

  useEffect(() => {
    sendLinkViewedEvent();
  }, []);

  // Sync the ref with the current send function whenever it updates
  useEffect(() => {
    sendRef.current = handleClickSend;
  }, [handleClickSend]);

  const hideCancelModal = () => {
    sendModalClosedEvent();
    hideModal();
  };

  const openModal = () => {
    sendUserClickedOnLinkEvent();
    const ModalContent = () => (
      <NpeFreeTextModal
        companyName={companyName}
        hide={hideCancelModal}
        onContinue={() => sendRef.current()}
        emailModalSetters={
          {
            setFreeTextSenderName,
            setFreeTextSenderContactInfo,
            setFreeTextSenderContactType,
            setFreeTextMessage,
          } as EmailModalSetters
        }
        emailModalGetters={
          {
            freeTextSenderName,
            freeTextSenderContactInfo,
            freeTextSenderContactType,
            freeTextMessage,
          } as EmailModalGetters
        }
      />
    );
    showModal({
      component: ModalContent,
      componentProps: {
        closeOnClickOutSide: false,
        closeOnEsc: false,
        showCloseButton: false,
      },
    });
  };

  useEffect(() => {
    setOINPEventStatus(freeTextEmailOINPEventStatus);
    freeTextEmailOINPEventStatus && hideCancelModal();
  }, [freeTextEmailOINPEventStatus]);

  return (
    <Fragment>
      {freeTextEmailOINPEventStatus === undefined && (
        <div className={'link-to-modal-wrapper'}>
          <span>
            <FormattedMessage
              id={'GROWTH_FREE_TEXT_EMAIL.NEED_SOMETHING_ELSE'}
              defaultMessage={'Need something else from this merchant?'}
            />{' '}
            <a className={'modal-link'} href={'#'} onClick={openModal}>
              <FormattedMessage
                id={'GROWTH_FREE_TEXT_EMAIL.NEED_SOMETHING_ELSE.LINK'}
                defaultMessage={'Send a message'}
              />
            </a>
          </span>
        </div>
      )}

      {/*language=SCSS*/}
      <style jsx>{`
        .link-to-modal-wrapper {
          @media screen and (max-width: ${breakpoints.sm}) {
            font-size: 14px;
          }
          display: flex;
          max-width: 100%;
          justify-content: center;
          word-break-wrap: nowrap;
          text-align: center;
          color: ${colors.gray01};
          padding-top: 10px;

          .modal-link {
            color: ${colors.blue};
            font-weight: bold;
            text-decoration: none;
            padding: 2px;

            :hover {
              text-decoration: underline;
            }
          }
        }
      `}</style>
    </Fragment>
  );
};

// @ts-ignore
const mapStateToProps = ({ insight: { freeTextEmailOINPEventStatus } }) => {
  return {
    freeTextEmailOINPEventStatus,
  };
};

export default connect(mapStateToProps, {
  sendNpeFreeTextEmailEvent,
})(NpeFreeTextExperiment);
