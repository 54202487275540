import React from 'react';

import { colors } from 'styles/cp';

export interface Props extends React.PropsWithChildren<React.HTMLAttributes<HTMLInputElement>> {
  value?: string | number;
  theme?: 'qbo';
}
const SelectQboStyle: React.FC<Props> = (props) => {
  const { children, ...rest } = props;
  return (
    <div className={'select'}>
      <input name="selection" type="list" readOnly id="standard-select" {...rest}>
        {children}
      </input>
      {/* language=scss */}
      <style jsx>{`
        .select {
          position: relative;
          margin: 4px 0;
        }

        input {
          cursor: pointer;
          color: ${colors.gray};
          appearance: none;
          background: transparent
            url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' color='black' viewBox='0 0 9 5'><g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'><path d='M4,3.49129715 L0.975536588,0.183275523 C0.752115996,-0.0610918409 0.390986036,-0.0610918409 0.167565444,0.183275523 C-0.055855148,0.427642886 -0.055855148,0.822630543 0.167565444,1.06699791 L3.59601443,4.81688072 C3.70743902,4.93875191 3.85371951,5 4,5 C4.14628049,5 4.29256098,4.93875191 4.40398557,4.81688072 L7.83243456,1.06699791 C8.05585515,0.822630543 8.05585515,0.427642886 7.83243456,0.183275523 C7.60901396,-0.0610918409 7.247884,-0.0610918409 7.02446341,0.183275523 L4,3.49129715 Z' fill='grey'/></g></svg>")
            no-repeat;
          background-position-x: 95%;
          background-position-y: 11px;
          padding: 8px 10px;
          border: 1px solid ${colors.gray05};
          border-radius: 4px;
          width: 100%;
          font-weight: 500;
          max-height: 36px;
          font-family: AvenirNextforINTUIT-Regular;
          :focus-visible {
            ${props.theme === 'qbo' && 'outline-color: #2ca01c;'}
          }
        }
      `}</style>
    </div>
  );
};
export default SelectQboStyle;
